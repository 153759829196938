
import { NativeModules } from 'react-native';

const { RNPreventScreenshot } = NativeModules;

export default {
    enabled: function (enable, type) {
        RNPreventScreenshot.enabled(enable, type || 'splash')
    }
};

